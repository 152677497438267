import * as React from "react"
import { Link } from "gatsby"

//import Layout from "../components/Layout"
import Seo from "../components/SEO"
import { StaticImage } from "gatsby-plugin-image"
import Blob from "../components/Blob"
import { colors } from "../assets/icons"

const SobreNosPage = () => (
  <>
    <div className="artigos-head">
      <div className="container">
        <span className="title">Sobre nós</span>
      </div>
    </div>
    <section>
      <div className="sobre-nos-head">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <p className="title">Maria Manuel Vieira</p>
            </div>
            <div className="col-lg-6">
              <p className="labels">
                Advogada — Criminóloga — Mestre em Direito Judiciário
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="sobre-nos-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <StaticImage
                src="../images/maria-manuel-vieira.jpeg"
                alt="maria manuel vieira"
              />
            </div>
            <div className="col-lg-6 offset-lg-1">
              <p className="title">Eclética, Determinada e Perfecionista</p>
              <p className="description">
                3 palavras que definem um espírito empreendedor e dinâmico que
                me caracteriza.
              </p>
              <p className="description">
                A minha paixão pelo Direito surgiu no decurso de Criminologia,
                por aquele que é o ramo mais atentatório da liberdade individual
                do cidadão – o Direito Criminal – precisamente pela faculdade a
                ele intrínseca de coartar o valor que mais priorizo, a
                liberdade.
              </p>

              <div className="skills">
                <div className="row">
                  <div className="col-lg-6">
                    <p className="subtitle text-primary">Áreas de formação</p>
                    <ul>
                      <li>Licenciatura em Criminologia</li>
                      <li>Licenciatura em Direito</li>
                      <li>Mestrado em Direito Judiciário</li>
                    </ul>

                    <p className="subtitle text-primary mt-lg-5">Idiomas</p>
                    <ul>
                      <li>Português (Nativo)</li>
                      <li>Inglês (B2.1)</li>
                    </ul>
                  </div>
                  <div className="col-lg-6">
                    <p className="subtitle text-primary">Reconhecimentos</p>
                    <ul>
                      <li>Prémio de Mérito</li>
                      <li>Criminologia</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="blob">
            <Blob
              size={200}
              color={colors.primaryLight}
              blobShape={1}
              borderWidth={2}
            />
          </div>
        </div>
      </div>
    </section>
  </>
)

export const Head = () => <Seo title="Sobre nós" />

export default SobreNosPage
